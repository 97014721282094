import { SET_BANNER_MESSAGE, SetBannerMessageAction } from '@/types/store/actions';
import { BannerMessage } from '@/types/store/reducers';

const initialState: BannerMessage = {
	flash: false,
	message: '',
	isError: false,
};

export function bannerMessageReducer(state = initialState, action: SetBannerMessageAction): BannerMessage {
	switch (action.type) {
		case SET_BANNER_MESSAGE:
			return action.payload;
		default:
			return state;
	}
}
