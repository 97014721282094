interface TEnvConfig {
	readonly apiUrl: string;
	readonly calmHealthWebUrl: string;
	readonly partnerPortalUrl: string;
	readonly partnerWebUrl: string;
	readonly oktaAppClientId: string;
	readonly oktaTokenIssuer: string;
	readonly stripeToken: string;
	readonly cookieDomain: string;
	readonly hermesEndpoint: string;
	readonly calmEnv: string;
	readonly amplitudeApiKey: string;
	readonly ssoRelayStateUrl: string;
	readonly sftpUrl: string;
	readonly hrReferralForm: string;
	readonly marketoBaseUrl: string;
	readonly marketoMunchkinId: string;
	readonly marketoContactFormId: string;
	readonly mswMockingEnabled: boolean;
	readonly salesforceTestAccountId: string;
	readonly auth0Domain: string;
	readonly auth0ClientId: string;
}

export const EnvConfig = {
	apiUrl: process.env.APP_API_URL,
	calmHealthWebUrl: process.env.CALM_HEALTH_WEB_URL,
	partnerPortalUrl: process.env.CALM_PARTNER_PORTAL_URL,
	partnerWebUrl: process.env.CALM_WWW_URL,
	oktaAppClientId: process.env.OKTA_CALM_PARTNER_PORTAL_APP_CLIENT_ID,
	oktaTokenIssuer: process.env.OKTA_CALM_PARTNER_PORTAL_TOKEN_ISSUER,
	stripeToken: process.env.STRIPE_TOKEN,
	cookieDomain: process.env.COOKIE_DOMAIN,
	hermesEndpoint: process.env.HERMES_ENDPOINT,
	calmEnv: process.env.CALM_ENV,
	amplitudeApiKey: process.env.AMPLITUDE_API_KEY,
	ssoRelayStateUrl: process.env.SSO_RELAY_STATE_URL,
	sftpUrl: process.env.SFTP_URL,
	hrReferralForm: process.env.HR_REFERRAL_FORM,
	marketoBaseUrl: process.env.MARKETO_BASE_URL,
	marketoMunchkinId: process.env.MARKETO_MUNCHKIN_URL,
	marketoContactFormId: process.env.MARKETO_CONTACT_FORM_ID,
	mswMockingEnabled: process.env.MSW_MOCKING_ENABLED === 'true',
	salesforceTestAccountId: process.env.SALESFORCE_TEST_ACCOUNT_ID,
	segmentWriteKey: process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY,
	auth0Domain: process.env.NEXT_PUBLIC_AUTH0_DOMAIN,
	auth0ClientId: process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID,
} as TEnvConfig;

if (
	EnvConfig.apiUrl === null ||
	EnvConfig.partnerPortalUrl === null ||
	EnvConfig.partnerWebUrl === null ||
	EnvConfig.oktaTokenIssuer === null ||
	EnvConfig.oktaAppClientId === null ||
	EnvConfig.stripeToken === null ||
	EnvConfig.cookieDomain === null ||
	EnvConfig.hermesEndpoint === null ||
	EnvConfig.calmEnv === null ||
	EnvConfig.amplitudeApiKey === null ||
	EnvConfig.ssoRelayStateUrl === null ||
	EnvConfig.sftpUrl === null ||
	EnvConfig.hrReferralForm === null ||
	EnvConfig.marketoBaseUrl === null ||
	EnvConfig.marketoMunchkinId === null ||
	EnvConfig.marketoContactFormId === null ||
	EnvConfig.salesforceTestAccountId === null ||
	EnvConfig.auth0Domain === null ||
	EnvConfig.auth0ClientId === null
) {
	throw new Error(`Incomplete env config: ${JSON.stringify(EnvConfig)}`);
}
