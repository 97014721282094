import { SET_LOADING, SetLoadingAction } from '@/types/store/actions';
import { Loading } from '@/types/store/reducers';

const initialState: Loading = true;

export function loadingReducer(state = initialState, action: SetLoadingAction): Loading {
	switch (action.type) {
		case SET_LOADING:
			return action.payload;
		default:
			return state;
	}
}
