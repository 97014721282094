import { SET_USER, SetUserAction } from '@/types/store/actions';
import { User } from '@/types/store/reducers';

const initialState: User = {};

export function userReducer(state = initialState, action: SetUserAction): User {
	switch (action.type) {
		case SET_USER:
			return { ...action.payload };
		default:
			return state;
	}
}
