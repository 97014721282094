import { SET_SITEWIDE_BANNER, SetSitewideBannerAction } from '@/types/store/actions';
import { SitewideBanner } from '@/types/store/reducers';

const initialState: SitewideBanner = {
	bannerCode: undefined,
	bannerInfo: null,
};

export function sitewideBannerReducer(state = initialState, action: SetSitewideBannerAction): SitewideBanner {
	switch (action.type) {
		case SET_SITEWIDE_BANNER:
			return action.payload;
		default:
			return state;
	}
}
