import { combineReducers } from 'redux';

import { BannerMessage, Loading, SitewideBanner, User } from '@/types/store/reducers';

import { bannerMessageReducer, loadingReducer, sitewideBannerReducer, userReducer } from './reducers';

export interface AppState {
	user: User;
	loading: Loading;
	bannerMessage: BannerMessage;
	sitewideBanner: SitewideBanner;
}

export const rootReducer = combineReducers<AppState>({
	user: userReducer,
	loading: loadingReducer,
	bannerMessage: bannerMessageReducer,
	sitewideBanner: sitewideBannerReducer,
});
