import { applyMiddleware, compose, createStore as reduxCreateStore, Store } from 'redux';
import reduxThunk from 'redux-thunk';

import { AnyObject } from '@/types/anyObject';

import { rootReducer } from './index';

declare global {
	interface Window {
		__REDUX_DEVTOOLS_EXTENSION_COMPOSE__: typeof compose;
		store: ReturnType<typeof reduxCreateStore> | undefined;
	}
}

const composeEnhancers =
	(typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

function createStore(initialState: AnyObject = {}): Store {
	const middleware = applyMiddleware(reduxThunk);

	const store = reduxCreateStore(rootReducer, initialState, composeEnhancers(middleware));

	if (typeof window !== 'undefined') {
		window.store = store;
	}
	return store;
}

export default createStore;
